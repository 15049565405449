<!-- 商超欠款-查看详情 -->
<template>
  <div>
    <back />
    <div class="detail" v-loading="loading">
      <h3 class="title">基本信息：</h3>
      <div>
        <el-row class="row">
          <el-col :span="8">
            <div class="col"><span class="txt">缴费单号:</span>{{ detail?.orderNum }}</div>
            <div class="col"><span class="txt">欠款总额(元):</span>{{ detail?.orderAmount }}</div>
          </el-col>
          <el-col :span="8">
            <div class="col"><span class="txt">结算时间:</span>{{ detail?.confirmTime }}</div>
            <div class="col"><span class="txt">缴费状态:</span>{{ detail?.status == '0' ? '未缴费' : detail?.status == '1' ?
              '已缴费' : '-' }}</div>
          </el-col>
          <el-col :span="8">
            <div class="col"><span class="txt">结算周期:</span>{{ detail?.startDate + '~' + detail?.endDate }}</div>
            <div class="col"><span class="txt">缴费时间:</span>{{ detail?.payTime }}</div>
          </el-col>
        </el-row>
      </div>

      <h3 class="title">订单信息：</h3>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="orderNum" label="订单号"
            width="150"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="orderSource" label="订单类型">
            <template slot-scope="scope">
              <span v-if="scope.row.orderSource == 1">线下订单</span>
              <span v-if="scope.row.orderSource == 2">线上订单</span>
              <span v-if="scope.row.orderSource == 3">会员充值</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="orderType" label="结算类型">
            <template slot-scope="scope">
              <span v-if="scope.row.orderType == 1">销售</span>
              <span v-if="scope.row.orderType == 2">退货</span>
              <span v-if="scope.row.orderType == 3">充值</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" prop="payType" label="支付方式" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ getOrderTypeText(scope.row.payType) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="payAmount" label="金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="supplyAmount" label="进货金额(元)"
            width="140"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="deliveryAmount" label="骑手配送费(元)"
            width="140"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopAddFreight" label="运费加价(元)"
            width="140"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="settlementAmount" label="结算金额(元)"
            width="140"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="时间"
            width="180"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="200" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      detail: {},
      id: '',
      shopId: '',
      startDate: '',
      endDate: '',
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.startDate = this.$route.query.startDate;
    this.endDate = this.$route.query.endDate;
    this.shopId = this.$route.query.shopId;
    this.getDetails();
    this.getSettlementInfoList();
  },
  methods: {
    getDetails() {
      this.loading = true;
      this.$axios.get(this.$api.selectSettlementArrearsPayDetail, {
        params: {
          id: this.id
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.detail = res.data.result;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    getSettlementInfoList() {
      this.loading = true;
      let params = {
        billStatus: this.accountValue || null, // 分账状态
        orderNum: this.orderValue || null, // 搜索订单号
        settlementType: 1,

        startTime: this.startDate || null,
        endTime: this.endDate || null,
        shopId: this.shopId || null,
        orderSource: 2,
        orderType: 1,
        payType: 1,
        arrearsType: 1,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$axios.get(this.$api.getSettlementInfoList, {
        params
      })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getSettlementInfoList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.onSearch();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getSettlementInfoList();
    },
    // 查看
    handleCheck(row) {
      if (row.orderSource == 1 || row.orderSource == 2) {
        this.$router.push({
          path: '/settlement/detail/shopDetailVo',
          query: {
            id: row.id
          }
        })
      } else if (row.orderSource == 3) {
        this.$router.push({
          path: '/settlement/detail/membershipCardPayDetail',
          query: {
            orderCode: row.orderNum
          }
        })
      }

    },
    // 支付类型
    getOrderTypeText(status) {
      switch (String(status)) {
        case '0':
          return '现金支付';
        case '1':
          return '线上支付';
        case '3':
          return '会员卡支付';
        default:
          return '';
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.detail {
  padding: 20px;

  .title {
    margin-bottom: 25px;
  }

  .row {
    color: #707070;
  }

  .col {
    margin-bottom: 25px;
  }

  .red {
    color: red;
  }

  .txt {
    margin-right: 8px;
  }

}
</style>
